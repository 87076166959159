export const checkCurrentUser = () => {
  let user;
  try {
    user = JSON.parse(localStorage.getItem('readready_user'));
  } catch (e) {
    console.log(e);
  }
  if (!user?.id) {
    delete localStorage.readready_user;
    delete localStorage.readready_jwt;
  }
  return user;
};

export const updateSubscriptionUserStorage = (val) => {
  let userData = JSON.parse(localStorage.getItem('readready_user'));
  userData.is_subscription = val;
  localStorage.setItem('readready_user', JSON.stringify(userData));
};

export const downloadFileFromResponse = (response, filename) => {
  const blobUrl = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = blobUrl
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(blobUrl)
}