import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'landing',
    component: () =>
      import(/* webpackChunkName: "landing" */ '../views/LandingPage.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/LoginPage.vue')
  },
  {
    path: '/sign-up',
    name: 'registration',
    component: () =>
      import(
        /* webpackChunkName: "registration" */ '../views/RegistrationPage.vue'
      )
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "forgotPassword" */ '../views/ForgotPassword.vue'
      )
  },
  {
    path: '/set-forgot-password',
    name: 'setForgotPassword',
    component: () =>
      import(
        /* webpackChunkName: "setForgotPassword" */ '../views/SetForgotPassword.vue'
      )
  },
  {
    path: '/update-user-email',
    name: 'updateUserEmailToken',
    component: () =>
      import(
        /* webpackChunkName: "updateUserEmailToken" */ '../views/UpdateUserEmailToken.vue'
      )
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/HomePage.vue')
  },
  {
    path: '/books/search-results',
    name: 'SearchResultsPage',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "SearchResultsPage" */ '../views/SearchResultsPage.vue'
      )
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "profile" */ '../views/ProfilePage.vue')
  },
  {
    path: '/create-project',
    name: 'createProject',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "createProject" */ '../views/CreateProjectPage.vue'
      )
  },
  {
    path: '/:book_id',
    name: 'bookView',
    component: () =>
      import(/* webpackChunkName: "bookView" */ '../views/BookView.vue')
  },
  {
    path: '/edit/ebook/:id',
    name: 'editEbook',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(/* webpackChunkName: "editEbook" */ '../views/EditEbookPage.vue')
  },
  {
    path: '/choose-template/:id',
    name: 'chooseTemplate',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "chooseTemplate" */ '../views/ChooseTemplatePage.vue'
      )
  },
  {
    path: '/subscription',
    name: 'subscription',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "subscription" */ '../views/SubscriptionPage.vue'
      )
  },
  {
    path: '/choose-plan',
    name: 'choosePlan',
    component: () =>
      import(
        /* webpackChunkName: "choosePlan" */ '../views/ChoosePlanPage.vue'
      )
  },
  {
    path: '/subscriptions/plans',
    name: 'subscriptionsPlans',
    meta: {
      requiresAuth: true
    },
    component: () =>
      import(
        /* webpackChunkName: "subscriptionsPlans" */ '../views/SubscriptionsPlans.vue'
      )
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: () =>
      import(
        /* webpackChunkName: "privacyPolicy" */ '../views/PrivacyPolicy.vue'
      )
  },
  {
    path: '/about',
    name: 'aboutPage',
    component: () =>
      import(/* webpackChunkName: "aboutPage" */ '../views/AboutPage.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: 'active-link',
  routes
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
  }

  const user = JSON.parse(localStorage.getItem('readready_user'));
  const jwt = localStorage.getItem('readready_jwt');

  if (to.meta?.requiresAuth && !user) {
    return next({
      path: '/login',
      params: { nextUrl: to.fullPath }
    });
  }
  if (!jwt) {
    return next({
      path: '/',
      params: { nextUrl: to.fullPath }
    });
  }
  if (jwt && user?.email && (!user?.is_subscription ?? false)) {
    return next({
      path: '/choose-plan',
      params: {}
    });
  }
  next();
});

export default router;
