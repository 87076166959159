import { createStore } from 'vuex'
import { checkCurrentUser } from '@/helpers/auth.helper';

export default createStore({
  state: {
    loading: false,
    login_email: '',
    logged_user:{}
  },

  getters: {
    getLogEmail(state){
     return state.login_email
    },
    getLoggedUser(state) {
      const logged_user = state.logged_user || {};
      if (!logged_user || !logged_user?.id) {
        state.logged_user = checkCurrentUser() || {};
      }
      return state.logged_user;
    }
  },
  mutations: {
    setLoggedUser(state, val) {
      if (val) {
        localStorage.setItem('readready_user', JSON.stringify(val));
      }
      state.logged_user = val;
    },
    setLogEmail(state, val) {
      state.login_email = val;
    },
    showLoader(state) {
      state.loading = true;
    },
    hideLoader(state) {
      state.loading = false;
    }
  },
  actions: {
  },
  modules: {
  }
})
